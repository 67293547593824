/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, inject, signal, WritableSignal } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { IconFieldModule } from 'primeng/iconfield';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ExtraInfoService } from 'src/app/services/extra-info.service';
import { ExtraInfoConfig, RailingExtraInfo } from 'src/app/types/extra-info';
import { showDataFetchError, showSaveError, showSaveSuccess } from 'src/app/utils/toasts';

@Component({
  selector: 'app-extra-info',
  standalone: true,
  imports: [
    CardModule,
    CommonModule,
    ConfirmDialogModule,
    ButtonModule,
    TableModule,
    IconFieldModule,
    InputTextModule,
    InputIconModule,
    ReactiveFormsModule,
    TabViewModule,
    InputGroupModule,
    ChipModule,
    FieldsetModule,
    PanelModule,
    ProgressSpinnerModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './extra-info.component.html',
  styleUrl: './extra-info.component.less',
})
export class ExtraInfoComponent {
  private messageService = inject(MessageService);

  protected extraInfoForm: FormGroup;
  protected loading: WritableSignal<boolean> = signal(true);
  protected saving: WritableSignal<boolean> = signal(false);

  // Helper to easily access nested form groups for display in template
  get stairTypeForm() {
    return <FormGroup>this.extraInfoForm.get('stairType');
  }
  get railingSteigelanderForm() {
    return <FormGroup>this.extraInfoForm.get('railingSteiggelander');
  }
  get railingParapetForm() {
    return <FormGroup>this.extraInfoForm.get('railingParapet');
  }
  get ceilingCasingArray() {
    return <FormArray>this.extraInfoForm.get('ceilingCasing');
  }
  get risersArray() {
    return <FormArray>this.extraInfoForm.get('risers');
  }

  constructor(
    private fb: FormBuilder,
    private extraInfoService: ExtraInfoService,
  ) {}

  ngOnInit(): void {
    // Initialize empty form structure
    this.initForm();

    this.extraInfoService.getExtraInfo().subscribe({
      next: config => {
        this.populateForm(config);
        this.loading.set(false);
      },
      error: err => {
        console.error('Error loading extra info:', err);
        this.loading.set(false);
        showDataFetchError(this.messageService);
      },
    });
  }

  initForm(): void {
    this.extraInfoForm = this.fb.group({
      stairType: this.fb.group({
        kragarmtreppe: this.fb.array([]),
        stahlwangentreppe: this.fb.array([]),
        faltwerktreppe: this.fb.array([]),
        holzwangentreppe: this.fb.array([]),
        mittelholmtreppe: this.fb.array([]),
        faltwerkoptiktreppe: this.fb.array([]),
        beton: this.fb.array([]),
      }),
      railingSteiggelander: this.createRailingFormGroup(),
      railingParapet: this.createRailingFormGroup(),
      ceilingCasing: this.fb.array([]),
      risers: this.fb.array([]),
    });
  }

  createRailingFormGroup(): FormGroup {
    return this.fb.group({
      standardHolz: this.fb.group({
        kiefer: this.fb.array([]),
        buche: this.fb.array([]),
        ahorn: this.fb.array([]),
        esche: this.fb.array([]),
        eiche: this.fb.array([]),
        kirsche: this.fb.array([]),
      }),
      stahlstabe: this.fb.array([]),
      'glas mit holz': this.fb.array([]),
      'glas mit edelstahl': this.fb.array([]),
      'sicherheitsglas eingelassen': this.fb.array([]),
      'sicherheitsglas seitlich befestigt': this.fb.array([]),
      'glas mit stahlprofil': this.fb.array([]),
      relinggelander: this.fb.array([]),
      vollholzgelander: this.fb.array([]),
    });
  }

  // Helper to access form arrays dynamically
  getFormArray(group: FormGroup, key: string): FormArray {
    return <FormArray>group.get(key);
  }

  // Helper for nested standard wood form arrays
  getStandardHolzArray(railingGroup: FormGroup, key: string): FormArray {
    return <FormArray>(<FormGroup>railingGroup.get('standardHolz')).get(key);
  }

  // Add new item to a form array
  addItem(formArray: FormArray, value: string = ''): void {
    formArray.push(new FormControl(value));
  }

  // Remove item from form array
  removeItem(formArray: FormArray, index: number): void {
    formArray.removeAt(index);
  }

  // Populate form with data from API
  populateForm(config: ExtraInfoConfig): void {
    // Clear existing arrays
    this.resetFormArrays();

    // Populate stair types
    Object.entries(config.stairType).forEach(([key, values]) => {
      const formArray = this.getFormArray(this.stairTypeForm, key);
      values.forEach(value => this.addItem(formArray, value));
    });

    // Populate railing Steiggeländer
    this.populateRailingForm(this.railingSteigelanderForm, config.railingSteiggelander);

    // Populate railing Parapet (Brüstungsgeländer)
    this.populateRailingForm(this.railingParapetForm, config.railingParapet);

    // Populate ceiling casing
    config.ceilingCasing.forEach(value => {
      this.addItem(this.ceilingCasingArray, value);
    });

    // Populate risers
    config.risers.forEach(value => {
      this.addItem(this.risersArray, value);
    });
  }

  // Helper to populate railing form groups
  populateRailingForm(formGroup: FormGroup, railing: RailingExtraInfo): void {
    // Populate standard wood types
    if (railing.standardHolz) {
      Object.entries(railing.standardHolz).forEach(([key, values]) => {
        const formArray = this.getStandardHolzArray(formGroup, key);
        values.forEach(value => this.addItem(formArray, value));
      });
    }

    // Populate other railing types
    const railingKeys = [
      'stahlstabe',
      'glas mit holz',
      'glas mit edelstahl',
      'sicherheitsglas eingelassen',
      'sicherheitsglas seitlich befestigt',
      'glas mit stahlprofil',
      'relinggelander',
      'vollholzgelander',
    ];

    // Type guard function
    function isKeyOfRailingExtraInfo(key: string): key is keyof RailingExtraInfo {
      return key in railing; // Assuming 'railing' is accessible here
    }

    railingKeys.forEach(key => {
      if (isKeyOfRailingExtraInfo(key) && railing[key]) {
        const formArray = this.getFormArray(formGroup, key);

        // Check if it's an array first
        if (Array.isArray(railing[key])) {
          (<string[]>railing[key]).forEach((value: string) => this.addItem(formArray, value));
        }
        // Handle case where it's an object with nested arrays
        else {
          // If it's the standardHolz object with nested arrays
          const nestedObj = <{ [key: string]: string[] }>railing[key];
          Object.keys(nestedObj).forEach(nestedKey => {
            const nestedFormArray = this.getNestedFormArray(formGroup, key, nestedKey);
            nestedObj[nestedKey].forEach((value: string) => this.addItem(nestedFormArray, value));
          });
        }
      }
    });
  }

  getNestedFormArray(formGroup: FormGroup, key: string, nestedKey: string): FormArray {
    return <FormArray>formGroup.get(`${key}.${nestedKey}`);
  }

  // Reset all form arrays to empty
  resetFormArrays(): void {
    // Reset stair type arrays
    Object.keys(this.stairTypeForm.controls).forEach(key => {
      const control = <FormArray>this.stairTypeForm.get(key);
      control.clear();
    });

    // Reset railing Steiggeländer arrays
    this.resetRailingFormArrays(this.railingSteigelanderForm);

    // Reset railing Parapet arrays
    this.resetRailingFormArrays(this.railingParapetForm);

    // Reset simple arrays
    this.ceilingCasingArray.clear();
    this.risersArray.clear();
  }

  // Helper to reset all arrays in a railing form group
  resetRailingFormArrays(railingForm: FormGroup): void {
    // Reset standard wood arrays
    const standardHolz = <FormGroup>railingForm.get('standardHolz');
    Object.keys(standardHolz.controls).forEach(key => {
      const control = <FormArray>standardHolz.get(key);
      control.clear();
    });

    // Reset other railing arrays
    const railingKeys = [
      'stahlstabe',
      'glas mit holz',
      'glas mit edelstahl',
      'sicherheitsglas eingelassen',
      'sicherheitsglas seitlich befestigt',
      'glas mit stahlprofil',
      'relinggelander',
      'vollholzgelander',
    ];

    railingKeys.forEach(key => {
      const control = <FormArray>railingForm.get(key);
      control.clear();
    });
  }

  // Convert form data to ExtraInfoConfig structure
  getFormData(): ExtraInfoConfig {
    const formValue = this.extraInfoForm.value;

    // Process stair types - using the specialized function
    const stairType = this.processStairTypeFormGroup(formValue.stairType);

    // Process railings
    const railingSteiggelander = this.processRailingGroup(formValue.railingSteiggelander);
    const railingParapet = this.processRailingGroup(formValue.railingParapet);

    return {
      stairType,
      railingSteiggelander,
      railingParapet,
      ceilingCasing: formValue.ceilingCasing.filter((item: string) => item?.trim()),
      risers: formValue.risers.filter((item: string) => item?.trim()),
    };
  }

  // Process form group to remove empty arrays
  // Process stair type data specifically
  processStairTypeFormGroup(group: any): {
    kragarmtreppe: string[];
    stahlwangentreppe: string[];
    faltwerktreppe: string[];
    holzwangentreppe: string[];
    mittelholmtreppe: string[];
    faltwerkoptiktreppe: string[];
    beton: string[];
  } {
    const result: {
      kragarmtreppe: string[];
      stahlwangentreppe: string[];
      faltwerktreppe: string[];
      holzwangentreppe: string[];
      mittelholmtreppe: string[];
      faltwerkoptiktreppe: string[];
      beton: string[];
    } = {
      kragarmtreppe: [],
      stahlwangentreppe: [],
      faltwerktreppe: [],
      holzwangentreppe: [],
      mittelholmtreppe: [],
      faltwerkoptiktreppe: [],
      beton: [],
    };

    Object.entries(group).forEach(([key, values]) => {
      if (key in result) {
        // Filter out empty strings
        const filteredValues = (<string[]>values).filter(item => item?.trim());
        if (filteredValues.length > 0) {
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
          result[key as keyof typeof result] = filteredValues;
        }
      }
    });

    return result;
  }

  // Process railing form group
  processRailingGroup(railingGroup: any): RailingExtraInfo {
    const result: any = {
      standardHolz: {},
    };

    // Process standard wood
    Object.entries(railingGroup.standardHolz).forEach(([key, values]) => {
      const filteredValues = (<string[]>values).filter(item => item?.trim());
      if (filteredValues.length > 0) {
        result.standardHolz[key] = filteredValues;
      }
    });

    // If no standard wood types, delete the property
    if (Object.keys(result.standardHolz).length === 0) {
      delete result.standardHolz;
    }

    // Process other railing types
    const railingKeys = [
      'stahlstabe',
      'glas mit holz',
      'glas mit edelstahl',
      'sicherheitsglas eingelassen',
      'sicherheitsglas seitlich befestigt',
      'glas mit stahlprofil',
      'relinggelander',
      'vollholzgelander',
    ];

    railingKeys.forEach(key => {
      const filteredValues = railingGroup[key].filter((item: string) => item?.trim());
      if (filteredValues.length > 0) {
        result[key] = filteredValues;
      }
    });

    return <RailingExtraInfo>result;
  }

  // Save the form data
  saveExtraInfo(): void {
    this.saving.set(true);
    const config = this.getFormData();

    this.extraInfoService.updateExtraInfo(config).subscribe({
      next: response => {
        // Refresh form with saved data
        this.populateForm(response);
        showSaveSuccess(this.messageService);
        this.saving.set(false);
      },
      error: err => {
        console.error('Error saving extra info:', err);
        showSaveError(this.messageService);
        this.saving.set(false);
      },
    });
  }
}
