/* eslint-disable max-lines-per-function */
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { Subject } from 'rxjs';
import {
  ConfigEntry,
  CreateConfigEntry,
  DynamicConfigService,
} from 'src/app/services/dynamic-config.service';

@Component({
  selector: 'app-dynamic-config',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    CalendarModule,
    CardModule,
    FormsModule,
    ProgressSpinnerModule,
    TableModule,
    DropdownModule,
    InputSwitchModule,
  ],
  templateUrl: './dynamic-config.component.html',
  styleUrls: ['./dynamic-config.component.less'],
})
export class DynamicConfigComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  configs: ConfigEntry[] = [];
  newConfig: CreateConfigEntry = {
    key: '',
    value: '',
    type: 'string',
    description: '',
  };

  // Options for type selection dropdown
  typeOptions = [
    { label: 'String', value: 'string' },
    { label: 'Boolean', value: 'boolean' },
    { label: 'Number', value: 'number' },
  ];

  constructor(private dynamicConfigService: DynamicConfigService) {}

  ngOnInit() {
    this.loadConfigs();
  }

  loadConfigs() {
    this.dynamicConfigService.getAllConfigs().subscribe(data => {
      this.configs = data.map(config => ({
        ...config,
        value:
          config.type === 'boolean'
            ? config.value === true || config.value === 'true'
            : config.value,
      }));
    });
  }

  refreshConfigs() {
    this.dynamicConfigService.refreshCache().subscribe(() => {
      console.log('Cache refreshed!');
      this.loadConfigs();
    });
  }

  addConfig() {
    // Transform the value if the type is boolean
    const transformedConfig = {
      ...this.newConfig,
      value:
        this.newConfig.type === 'boolean'
          ? String(this.newConfig.value) // Convert boolean to 'true' or 'false'
          : this.newConfig.value,
    };

    this.dynamicConfigService.setConfig(transformedConfig).subscribe(config => {
      console.log('Config added:', config);
      this.loadConfigs();
      // Reset the form
      this.newConfig = { key: '', value: '', type: 'string', description: '' };
    });
  }

  updateBooleanConfig(config: ConfigEntry) {
    const updatedConfig: CreateConfigEntry = {
      key: config.key,
      value: String(config.value), // Convert value to string
      type: <'string' | 'boolean' | 'number'>config.type, // Ensure proper type
      description: config.description,
    };

    this.dynamicConfigService.setConfig(updatedConfig).subscribe(() => {
      console.log(`Boolean config "${config.key}" updated to`, config.value);
    });
  }

  updateConfig(config: ConfigEntry) {
    const updatedConfig: CreateConfigEntry = {
      key: config.key,
      value: String(config.value), // Convert value to string
      type: <'string' | 'boolean' | 'number'>config.type, // Ensure proper type
      description: config.description,
    };

    this.dynamicConfigService.setConfig(updatedConfig).subscribe(() => {
      console.log(`Config "${config.key}" updated to`, config.value);
    });
  }

  onTypeChange(type: string) {
    if (type === 'boolean') {
      // Initialize value as false when switching to boolean type
      this.newConfig.value = 'false';
    } else {
      // Clear value for other types
      this.newConfig.value = '';
    }
  }

  getTypeClass(type: string): string {
    switch (type) {
      case 'string':
        return 'p-tag-info';
      case 'boolean':
        return 'p-tag-success';
      case 'number':
        return 'p-tag-warning';
      default:
        return 'p-tag-secondary';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
