<!-- extra-info-editor.component.html -->
<div class="container">
  <div *ngIf="extraInfoForm" [formGroup]="extraInfoForm">
    <p-card>
      <h1>Extra Information Configuration</h1>
      <div *ngIf="loading()" class="mt-2">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <form *ngIf="!loading()" [formGroup]="extraInfoForm" (ngSubmit)="saveExtraInfo()">
        <!-- Stair Types Section -->
        <div class="mb-8 p-2" formGroupName="stairType">
          <h2 class="mb-2">Stair Types</h2>
          <div class="grid">
            <!-- Kragarmtreppe -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Kragarmtreppe</h3>
                <div formArrayName="kragarmtreppe">
                  <div
                    *ngFor="
                      let item of getFormArray(stairTypeForm, 'kragarmtreppe').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>

                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(stairTypeForm, 'kragarmtreppe'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(stairTypeForm, 'kragarmtreppe'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>
            <!-- Stahlwangentreppe -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Stahlwangentreppe</h3>
                <div formArrayName="stahlwangentreppe">
                  <div
                    *ngFor="
                      let item of getFormArray(stairTypeForm, 'stahlwangentreppe').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(stairTypeForm, 'stahlwangentreppe'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(stairTypeForm, 'stahlwangentreppe'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Faltwerktreppe -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Faltwerktreppe</h3>
                <div formArrayName="faltwerktreppe">
                  <div
                    *ngFor="
                      let item of getFormArray(stairTypeForm, 'faltwerktreppe').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(stairTypeForm, 'faltwerktreppe'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(stairTypeForm, 'faltwerktreppe'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Holzwangentreppe -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Holzwangentreppe</h3>
                <div formArrayName="holzwangentreppe">
                  <div
                    *ngFor="
                      let item of getFormArray(stairTypeForm, 'holzwangentreppe').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(stairTypeForm, 'holzwangentreppe'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(stairTypeForm, 'holzwangentreppe'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Mittelholmtreppe -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Mittelholmtreppe</h3>
                <div formArrayName="mittelholmtreppe">
                  <div
                    *ngFor="
                      let item of getFormArray(stairTypeForm, 'mittelholmtreppe').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(stairTypeForm, 'mittelholmtreppe'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(stairTypeForm, 'mittelholmtreppe'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Faltwerkoptiktreppe -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Faltwerkoptiktreppe</h3>
                <div formArrayName="faltwerkoptiktreppe">
                  <div
                    *ngFor="
                      let item of getFormArray(stairTypeForm, 'faltwerkoptiktreppe').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(stairTypeForm, 'faltwerkoptiktreppe'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(stairTypeForm, 'faltwerkoptiktreppe'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Beton -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Beton</h3>
                <div formArrayName="beton">
                  <div
                    *ngFor="
                      let item of getFormArray(stairTypeForm, 'beton').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(stairTypeForm, 'beton'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(stairTypeForm, 'beton'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Railing Steiggeländer Section -->
        <div class="mb-8 p-2" formGroupName="railingSteiggelander">
          <h2 class="mb-2">Railing - Steiggeländer</h2>

          <!-- Standard Holz Section -->
          <div formGroupName="standardHolz">
            <h3 class="mb-3">Standard Holz</h3>

            <div class="grid">
              <!-- Kiefer -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Kiefer</h4>
                  <div formArrayName="kiefer">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingSteigelanderForm, 'kiefer')
                          .controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="
                          removeItem(getStandardHolzArray(railingSteigelanderForm, 'kiefer'), i)
                        "
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingSteigelanderForm, 'kiefer'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>

              <!-- Buche -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Buche</h4>
                  <div formArrayName="buche">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingSteigelanderForm, 'buche').controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="
                          removeItem(getStandardHolzArray(railingSteigelanderForm, 'buche'), i)
                        "
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingSteigelanderForm, 'buche'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>

              <!-- Ahorn -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Ahorn</h4>
                  <div formArrayName="ahorn">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingSteigelanderForm, 'ahorn').controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="
                          removeItem(getStandardHolzArray(railingSteigelanderForm, 'ahorn'), i)
                        "
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingSteigelanderForm, 'ahorn'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>

              <!-- Esche -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Esche</h4>
                  <div formArrayName="esche">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingSteigelanderForm, 'esche').controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="
                          removeItem(getStandardHolzArray(railingSteigelanderForm, 'esche'), i)
                        "
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingSteigelanderForm, 'esche'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>

              <!-- Eiche -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Eiche</h4>
                  <div formArrayName="eiche">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingSteigelanderForm, 'eiche').controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="
                          removeItem(getStandardHolzArray(railingSteigelanderForm, 'eiche'), i)
                        "
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingSteigelanderForm, 'eiche'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>

              <!-- Kirsche -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Kirsche</h4>
                  <div formArrayName="kirsche">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingSteigelanderForm, 'kirsche')
                          .controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="
                          removeItem(getStandardHolzArray(railingSteigelanderForm, 'kirsche'), i)
                        "
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingSteigelanderForm, 'kirsche'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Other Railing Types -->
          <div class="grid">
            <!-- Stahlstabe -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Stahlstäbe</h3>
                <div formArrayName="stahlstabe">
                  <div
                    *ngFor="
                      let item of getFormArray(railingSteigelanderForm, 'stahlstabe').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(railingSteigelanderForm, 'stahlstabe'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingSteigelanderForm, 'stahlstabe'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Glas mit Holz -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Glas mit Holz</h3>
                <div formArrayName="glas mit holz">
                  <div
                    *ngFor="
                      let item of getFormArray(railingSteigelanderForm, 'glas mit holz').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(getFormArray(railingSteigelanderForm, 'glas mit holz'), i)
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingSteigelanderForm, 'glas mit holz'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Glas mit Edelstahl -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Glas mit Edelstahl</h3>
                <div formArrayName="glas mit edelstahl">
                  <div
                    *ngFor="
                      let item of getFormArray(railingSteigelanderForm, 'glas mit edelstahl')
                        .controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(getFormArray(railingSteigelanderForm, 'glas mit edelstahl'), i)
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingSteigelanderForm, 'glas mit edelstahl'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Sicherheitsglas eingelassen -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Sicherheitsglas eingelassen</h3>
                <div formArrayName="sicherheitsglas eingelassen">
                  <div
                    *ngFor="
                      let item of getFormArray(
                        railingSteigelanderForm,
                        'sicherheitsglas eingelassen'
                      ).controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(
                          getFormArray(railingSteigelanderForm, 'sicherheitsglas eingelassen'),
                          i
                        )
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="
                      addItem(getFormArray(railingSteigelanderForm, 'sicherheitsglas eingelassen'))
                    "
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Sicherheitsglas seitlich befestigt -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Sicherheitsglas seitlich befestigt</h3>
                <div formArrayName="sicherheitsglas seitlich befestigt">
                  <div
                    *ngFor="
                      let item of getFormArray(
                        railingSteigelanderForm,
                        'sicherheitsglas seitlich befestigt'
                      ).controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(
                          getFormArray(
                            railingSteigelanderForm,
                            'sicherheitsglas seitlich befestigt'
                          ),
                          i
                        )
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="
                      addItem(
                        getFormArray(railingSteigelanderForm, 'sicherheitsglas seitlich befestigt')
                      )
                    "
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Glas mit Stahlprofil -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Glas mit Stahlprofil</h3>
                <div formArrayName="glas mit stahlprofil">
                  <div
                    *ngFor="
                      let item of getFormArray(railingSteigelanderForm, 'glas mit stahlprofil')
                        .controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(getFormArray(railingSteigelanderForm, 'glas mit stahlprofil'), i)
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingSteigelanderForm, 'glas mit stahlprofil'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Relinggeländer -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Relinggeländer</h3>
                <div formArrayName="relinggelander">
                  <div
                    *ngFor="
                      let item of getFormArray(railingSteigelanderForm, 'relinggelander').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(getFormArray(railingSteigelanderForm, 'relinggelander'), i)
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingSteigelanderForm, 'relinggelander'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Vollholzgeländer -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Vollholzgeländer</h3>
                <div formArrayName="vollholzgelander">
                  <div
                    *ngFor="
                      let item of getFormArray(railingSteigelanderForm, 'vollholzgelander')
                        .controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(getFormArray(railingSteigelanderForm, 'vollholzgelander'), i)
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingSteigelanderForm, 'vollholzgelander'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Railing Parapet Section -->
        <div class="mb-8 p-2" formGroupName="railingParapet">
          <h2 class="mb-2">Railing - Brüstungsgeländer</h2>

          <!-- Standard Holz Section -->
          <div formGroupName="standardHolz">
            <h3 class="mb-3">Standard Holz</h3>

            <div class="grid">
              <!-- Kiefer -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Kiefer</h4>
                  <div formArrayName="kiefer">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingParapetForm, 'kiefer').controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="removeItem(getStandardHolzArray(railingParapetForm, 'kiefer'), i)"
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingParapetForm, 'kiefer'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>

              <!-- Buche -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Buche</h4>
                  <div formArrayName="buche">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingParapetForm, 'buche').controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="removeItem(getStandardHolzArray(railingParapetForm, 'buche'), i)"
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingParapetForm, 'buche'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>

              <!-- Ahorn -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Ahorn</h4>
                  <div formArrayName="ahorn">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingParapetForm, 'ahorn').controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="removeItem(getStandardHolzArray(railingParapetForm, 'ahorn'), i)"
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingParapetForm, 'ahorn'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>

              <!-- Esche -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Esche</h4>
                  <div formArrayName="esche">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingParapetForm, 'esche').controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="removeItem(getStandardHolzArray(railingParapetForm, 'esche'), i)"
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingParapetForm, 'esche'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>

              <!-- Eiche -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Eiche</h4>
                  <div formArrayName="eiche">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingParapetForm, 'eiche').controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="removeItem(getStandardHolzArray(railingParapetForm, 'eiche'), i)"
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingParapetForm, 'eiche'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>

              <!-- Kirsche -->
              <div class="col-6">
                <div class="p-2">
                  <h4 class="mb-2">Kirsche</h4>
                  <div formArrayName="kirsche">
                    <div
                      *ngFor="
                        let item of getStandardHolzArray(railingParapetForm, 'kirsche').controls;
                        let i = index
                      "
                      class="flex items-center mb-2"
                    >
                      <p-inputGroup>
                        <input
                          [formControlName]="i"
                          type="text"
                          pInputText
                          class="flex-grow p-2 mr-2"
                        />
                      </p-inputGroup>
                      <p-button
                        type="button"
                        severity="secondary"
                        (click)="removeItem(getStandardHolzArray(railingParapetForm, 'kirsche'), i)"
                      >
                        <span class="pi pi-trash"></span>
                      </p-button>
                    </div>
                    <p-button
                      type="button"
                      class="mt-2"
                      (click)="addItem(getStandardHolzArray(railingParapetForm, 'kirsche'))"
                    >
                      + Add Item
                    </p-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Other Railing Types Section for Parapet -->
          <div class="grid">
            <!-- Stahlstäbe -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Stahlstäbe</h3>
                <div formArrayName="stahlstabe">
                  <div
                    *ngFor="
                      let item of getFormArray(railingParapetForm, 'stahlstabe').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(railingParapetForm, 'stahlstabe'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingParapetForm, 'stahlstabe'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Glas mit Holz -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Glas mit Holz</h3>
                <div formArrayName="glas mit holz">
                  <div
                    *ngFor="
                      let item of getFormArray(railingParapetForm, 'glas mit holz').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(railingParapetForm, 'glas mit holz'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingParapetForm, 'glas mit holz'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Glas mit Edelstahl -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Glas mit Edelstahl</h3>
                <div formArrayName="glas mit edelstahl">
                  <div
                    *ngFor="
                      let item of getFormArray(railingParapetForm, 'glas mit edelstahl').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(getFormArray(railingParapetForm, 'glas mit edelstahl'), i)
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingParapetForm, 'glas mit edelstahl'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Sicherheitsglas eingelassen -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Sicherheitsglas eingelassen</h3>
                <div formArrayName="sicherheitsglas eingelassen">
                  <div
                    *ngFor="
                      let item of getFormArray(railingParapetForm, 'sicherheitsglas eingelassen')
                        .controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(
                          getFormArray(railingParapetForm, 'sicherheitsglas eingelassen'),
                          i
                        )
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="
                      addItem(getFormArray(railingParapetForm, 'sicherheitsglas eingelassen'))
                    "
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Sicherheitsglas seitlich befestigt -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Sicherheitsglas seitlich befestigt</h3>
                <div formArrayName="sicherheitsglas seitlich befestigt">
                  <div
                    *ngFor="
                      let item of getFormArray(
                        railingParapetForm,
                        'sicherheitsglas seitlich befestigt'
                      ).controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(
                          getFormArray(railingParapetForm, 'sicherheitsglas seitlich befestigt'),
                          i
                        )
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="
                      addItem(
                        getFormArray(railingParapetForm, 'sicherheitsglas seitlich befestigt')
                      )
                    "
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Glas mit Stahlprofil -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Glas mit Stahlprofil</h3>
                <div formArrayName="glas mit stahlprofil">
                  <div
                    *ngFor="
                      let item of getFormArray(railingParapetForm, 'glas mit stahlprofil').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="
                        removeItem(getFormArray(railingParapetForm, 'glas mit stahlprofil'), i)
                      "
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingParapetForm, 'glas mit stahlprofil'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Relinggeländer -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Relinggeländer</h3>
                <div formArrayName="relinggelander">
                  <div
                    *ngFor="
                      let item of getFormArray(railingParapetForm, 'relinggelander').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(railingParapetForm, 'relinggelander'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingParapetForm, 'relinggelander'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>

            <!-- Vollholzgeländer -->
            <div class="col-6">
              <div class="p-2">
                <h3 class="mb-2">Vollholzgeländer</h3>
                <div formArrayName="vollholzgelander">
                  <div
                    *ngFor="
                      let item of getFormArray(railingParapetForm, 'vollholzgelander').controls;
                      let i = index
                    "
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(getFormArray(railingParapetForm, 'vollholzgelander'), i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button
                    type="button"
                    class="mt-2"
                    (click)="addItem(getFormArray(railingParapetForm, 'vollholzgelander'))"
                  >
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Ceiling Casing Section -->
        <div class="mb-8 p-2">
          <div class="grid">
            <!-- Kragarmtreppe -->
            <div class="col-6">
              <div class="p-2">
                <h2 class="mb-2">Ceiling Casing</h2>

                <div formArrayName="ceilingCasing">
                  <div
                    *ngFor="let item of ceilingCasingArray.controls; let i = index"
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>
                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(ceilingCasingArray, i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button type="button" class="mt-2" (click)="addItem(ceilingCasingArray)">
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Risers Section -->
        <div class="mb-8 p-2">
          <div class="grid">
            <!-- Kragarmtreppe -->
            <div class="col-6">
              <div class="p-2">
                <h2 class="mb-2">Risers</h2>

                <div formArrayName="risers">
                  <div
                    *ngFor="let item of risersArray.controls; let i = index"
                    class="flex items-center mb-2"
                  >
                    <p-inputGroup>
                      <input
                        [formControlName]="i"
                        type="text"
                        pInputText
                        class="flex-grow p-2 mr-2"
                      />
                    </p-inputGroup>

                    <p-button
                      type="button"
                      severity="secondary"
                      (click)="removeItem(risersArray, i)"
                    >
                      <span class="pi pi-trash"></span>
                    </p-button>
                  </div>
                  <p-button type="button" class="mt-2" (click)="addItem(risersArray)">
                    + Add Item
                  </p-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Form Actions -->
        <div class="floating flex justify-content-end mt-6">
          <p-button type="submit" size="large" [disabled]="saving()">
            <span class="pi pi-save mr-2"></span>
            {{ saving() ? 'Saving...' : 'Save Configuration' }}
          </p-button>
        </div>
      </form>
    </p-card>
  </div>
</div>
