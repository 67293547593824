import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ExtraInfoConfig } from '../types/extra-info';
import { HttpBase } from '../utils/http-base';

@Injectable({
  providedIn: 'root',
})
export class ExtraInfoService {
  apiUrl = 'extra-info';

  constructor(private http: HttpBase) {}

  getExtraInfo(): Observable<ExtraInfoConfig> {
    return this.http.get<ExtraInfoConfig>(this.apiUrl);
  }

  updateExtraInfo(config: ExtraInfoConfig): Observable<ExtraInfoConfig> {
    // Our endpoint expects the payload to be wrapped in { extraInfoConfig: ... }
    return this.http.patch<ExtraInfoConfig>(this.apiUrl, { extraInfoConfig: config });
  }
}
