import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuItem } from 'primeng/api/menuitem';
import { ButtonModule } from 'primeng/button';
import { MenubarModule } from 'primeng/menubar';
import { AuthService, USERNAME_LS_KEY } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [ButtonModule, MenubarModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.less',
})
export class HeaderComponent implements OnInit {
  public username: string | null;
  public items: MenuItem[] | undefined;
  protected isDesktop: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  // eslint-disable-next-line max-lines-per-function
  ngOnInit() {
    this.checkScreenSize();

    this.username = localStorage.getItem(USERNAME_LS_KEY);

    this.items = [
      {
        label: 'Angebot Liste',
        icon: 'pi pi-fw pi-file',
        command: () => {
          this.goToDashboard();
        },
        items: [
          {
            label: 'Neues Preisangebot',
            icon: 'pi pi-fw pi-file-plus',
            command: () => {
              this.newQoute();
            },
          },
          {
            label: 'Angebot Liste',
            icon: 'pi pi-fw pi-folder-open',
            command: () => {
              this.goToDashboard();
            },
          },
        ],
      },
      {
        label: 'Bearbeiten',
        icon: 'pi pi-fw pi-pencil',
        items: [
          {
            label: 'Preise',
            icon: 'pi pi-fw pi-euro',
            command: () => {
              this.editPrices();
            },
          },
          {
            label: 'Stairs Info',
            icon: 'pi pi-fw pi-info',
            command: () => {
              this.editStairsInfo();
            },
          },
          {
            label: 'Texte für PDF/Email',
            icon: 'pi pi-fw pi-file-pdf',
            command: () => {
              this.editPdfEmailInfo();
            },
          },
          {
            label: 'Preise für Postleitzahlen',
            icon: 'pi pi-fw pi-map',
            command: () => {
              this.editPostalCodes();
            },
          },
        ],
      },
      {
        label: 'Stats global',
        icon: 'pi pi-fw pi-chart-bar',
        command: () => {
          this.goToGlobalStats();
        },
      },
      {
        label: 'Settings',
        icon: 'pi pi-fw pi-cog',
        command: () => {
          this.goToDynamicConfig();
        },
      },
    ];
  }

  public goToDashboard() {
    this.router.navigate(['./dashboard']);
  }

  public logout() {
    this.authService.logout();
  }

  public newQoute() {
    this.router.navigate(['./new-quote'], { relativeTo: this.activatedRoute });
  }

  public editPrices() {
    this.router.navigate(['./prices-edit'], { relativeTo: this.activatedRoute });
  }

  public editStairsInfo() {
    this.router.navigate(['./stairs-info'], { relativeTo: this.activatedRoute });
  }

  public editPostalCodes() {
    this.router.navigate(['./prices-edit'], { relativeTo: this.activatedRoute });
  }

  public editPdfEmailInfo() {
    this.router.navigate(['./pdf-email-extra-info-edit'], { relativeTo: this.activatedRoute });
  }
  public goToGlobalStats() {
    this.router.navigate(['./global-statistics'], { relativeTo: this.activatedRoute });
  }

  public goToDynamicConfig() {
    this.router.navigate(['./config'], { relativeTo: this.activatedRoute });
  }

  private checkScreenSize() {
    this.isDesktop = window.innerWidth > 640;
  }
}
